@import "../../../../styles/helper";

.mobile-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 9px;

  .brand_logo {
    height: 32px;
    position: absolute;
    top: 30px;
    left: 30px;
  }

  .back-btn {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    gap: 4px;
    align-items: center;
    color: #AFB6C0;
    margin-bottom: 8px;
    align-self: flex-start;

    .backIcon {
      font-size: 24px
    }
  }

  &__wrapper {
    max-width: 496px;
    display: flex;
    flex-direction: column;
    padding: calc(var(--base-padding) * 3) calc(var(--base-padding) * 2);
    border-radius: calc(var(--base-border-radius) * 0.5);
  }

  &__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  &__vertical-space {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__row-1 {
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: $white;
    font-size: 29px;
    font-weight: 700;
    line-height: 40px;
  }

  &__description {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: #AFB6C0;

    span {
      color: $white;
    }
  }

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 56px;
    height: 56px;
    background: #f2f4f7;
    border: 1px solid var(--color-input-border-light);
    border-radius: 32px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #1b1c1f;
  }

  &__specification {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #747e99;
  }

  &__mobile-number {
    color: var(--color-primary-dark);
  }

  button {
    background: rgba(51, 184, 122, 0.06);
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    color: #33b87a;
    width: 100%;
  }
}
