@import styles/helpers

.loanTable
    height: 100%
    overflow: hidden
    background: $n8
    border: 1px solid $n6
    border-radius: 8px
    +dark
        background: $dark
        border-color: $n2
    +t
        height: 100%

.head
    display: flex
    align-items: center
    padding: 8px 16px
    gap: 8px
    border-bottom: 1px solid $n6
    +dark
        border-color: $n2

.orderBook
    display: flex
    align-items: center
    margin-right: auto
    color: $n4
    font-weight: 600
    font-size: 14px

.activeSbaOrderBook
    border: 1px solid $n74 !important
    background: $n75 !important    

.searchField 
    position: relative
    padding: 10px
    

.searchInput 
    width: 100%
    padding: 10px 40px 10px 40px
    outline: none
    background-color: $n7
    +dark
      background-color: $n36
      color: white
    
.searchIcon 
    position: absolute
    top: 50%
    left: 20px
    transform: translateY(-50%)
    font-size: 20px
    color: $n4
    
.loanDataList
    height: 310px
    overflow-y: auto 

.emptyData
    height: 100%
    display: flex
    justify-content: center
    align-items: center

.loanData
    padding: 5px 0px 5px 25px
    border-top: 1px solid $n6
    +dark
        border-top: 1px solid $n2
    :hover
        cursor: pointer

.symbol
    font-size: 15px

.loanValue
    font-size: 12px
    line-height: 15px

.selectedLoan
    color: #1472FF

.pagination
    padding-top: 8px
    padding-bottom: 8px
    display: flex
    justify-content: center
    align-items: center
    border-top: 1px solid $n6
    +dark
        border-top: 1px solid $n2
    .pagination_line
        font-size: 12px
        margin-left: 5px
    .pagination_numbers
        margin-left: 30px
        display: flex
        gap: 5px
        align-items: center
        div
            width: 30px
            height: 30px
            display: grid
            place-items: center
            cursor: pointer
            line-height: normal
            border-radius: 50%
            border: 1px solid $n6
            background-color: $n7
            +dark
                background-color: $n36
                border: 1px solid $n3
    input
        margin-right: 5px
        width: 30px
        height: 30px
        max-width: 40px
        text-align: center
        background-color: $n7
        border: 1px solid $n6
        +dark
            background-color: $n36
            border: 1px solid $n3
            color: white

.disable
    cursor: not-allowed
    pointer-events: none
    opacity: 0.5