.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay--after-open {
  display: flex;
}

.Modal__box {
  width: 517px;
  min-height: 362px;
  border-radius: 4px;
  box-shadow: var(--shadow);
  border: solid 1px var(--color-border-2);
  background-color: var(--color-white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}

.Modal {
  display: flex;
  background-color: transparent;
  max-width: 1000px;
  align-items: center;
  margin: auto;
  padding: 10px;
}

.Modal:focus-visible {
  outline: none;
}

.ReactSettings_Modal.Modal__wrapper {
  width: 572px;
}

.Modal__wrapper {
  border-radius: 0px !important;
  background-color: #ffffff !important;
  width: 100%;
  position: relative;
  max-width: 800px !important;
}

.model-title-margin .Modal_title {
  margin-bottom: 0;
}

.Modal_title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.Modal__closeButton {
  position: absolute;
  cursor: pointer;
  background-color: transparent;
  border: none;
  top: 0;
  right: 16px;
  margin-top: 0 !important;
  cursor: pointer;
}

.Modal__closeIcon {
  color: #999999 !important;
  font-size: 28px;
}

.light-mode .Modal__closeIcon {
  color: #000 !important;
}

.Modal__hidden {
  display: none;
}

.Modal__header {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: var(--c-ink);
  margin-bottom: 24px;
}

.Modal__body {
  max-width: 100%;
  width: 413px;
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 14px;
  line-height: 24px;
  color: var(--c-ink-light);
}

.Modal__buttons-wrp {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  justify-content: center;
}

.Modal__buttons-wrp button {
  min-width: unset;
  height: unset;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 999 !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.exchange-modal-wrapper.ReactModal__Content {
  background-color: var(--color-bg-100-light) !important;
  padding: 10px;
}

.dark-mode .exchange-modal-wrapper.ReactModal__Content {
  background-color: var(--color-bg-80-dark) !important;
}
body .Modal__kyc.exchange-modal-wrapper.ReactModal__Content {
  max-width: 80% !important;
  background-color: white !important;
}

.dark-mode .exchange-modal-wrapper.ReactModal__Content {
  background-color: #1F1F1F !important;
}
.exchange-modal-wrapper .Modal__closeButton {
  top: -10px;
  right: -3px;
}

.exchange-modal-wrapper .Modal__wrapper {
  background-color: var(--color-bg-100-light) !important;
}

body .Modal__kyc.exchange-modal-wrapper .Modal__wrapper {
  padding: 0;
  background-color: white !important;
  max-width: 100% !important;
}
.dark-mode .exchange-modal-wrapper .Modal__wrapper {
  background-color: #1F1F1F !important;
}

.new-react-modal.ReactModal__Content {
  background-color: var(--color-bg-100-light) !important;
}

.dark-mode .new-react-modal.ReactModal__Content {
  background-color: var(--color-bg-80-dark) !important;
}

.new-react-modal .Modal__wrapper {
  background-color: var(--color-bg-100-light) !important;
}

.dark-mode .new-react-modal .Modal__wrapper {
  background-color: var(--color-bg-80-dark) !important;
}

