@import ../../../styles/helpers

.sidebarContainer
    width: 250px
    transition: width 240ms ease-out 0s
    background-color: var(--color-bg-100-light)
    border-right: 1px solid #E6E8EC
    display: flex
    flex-direction: column
    align-items: flex-start
    padding-bottom: 10px
    position: relative
    +dark
        background: $n17
        border-right: 1px solid #43464d

.collapse_inner
    width: 70px
    transition: width 240ms ease-out 0s
    display: flex
    flex-direction: column
    align-items: flex-start
    position: relative
    border-right: 1px solid #E6E8EC
    padding-bottom: 10px
    +dark
        background: $n17
        border-right: 1px solid #43464d

.sidebarBody
    flex: 1 1 
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between

.sidebarTabsContainer
    padding: 10px 0

.sidebarFooterTabsContainer
    padding: 10px 0

.brand_logo_img
    width: auto
    height: 64px
    transition: opacity 0.3s ease-in-out 

.logoSmall
    margin-left: 21px
    margin-top: 11.5px
    transform: translateY(-208%) translateX(-19%)
    height: 29px
.mlcSmall
    height: 32px
    margin-left: 26px
    margin-top: 34px
    transform: translateY(-208%) translateX(-19%)

.brandLogo
    height: 64px
    border-bottom: 1px solid #E6E8EC
    overflow: hidden
    position: relative

    +dark
        border-bottom: 1px solid #43464d

// Ensure initial visibility of SVG logo
.logoSmall
    &.visible
        visibility: visible

    &.hidden
        visibility: hidden
        z-index: -2
.mlcSmall
    &.visible
        visibility: visible

    // Ensure hidden state for PNG logo
    &.hidden
        visibility: hidden
        z-index: -2

.brand_logo_img
    &.visible
        visibility: visible

    // Ensure hidden state for PNG logo
    &.hidden
        visibility: hidden
        z-index: -2




.sidebar_tabs_container
    width: 250px
    padding-top: 24px

.sidebar_collapse_tabs_container
    width: 70px
    padding-top: 24px

.sidebarTitle
    width: 250px
    display: flex
    justify-content: flex-start
    align-items: center
    padding-inline: 20px 10px
    cursor: pointer
    color: #464C5C
    height: 50px
    +dark
        color: #ffffff

.collapseTitle
    display: flex
    justify-content: flex-start
    align-items: center
    padding-inline: 20px 10px
    height: 50px
    cursor: pointer
    color: #464C5C

.sidebarIcon
    font-size: 20px
    line-height: 1
    color: #747E99
    width: 20px
    +dark
        color: #878C99

.sidebar_text
    width: 250px
    padding: 16px 24px
    gap: 16px
    color: #747E99
    font-size: 14px
    font-weight: 600
    line-height: 20px
    transition: width 240ms ease-in-out 
    flex: 1 1
    +dark
        color: #878C99
    &:hover
        color: $p1
    &:active
        color: $p1

.activeTab
    color: $p1 !Important

.subPadding
    padding-left: 30px

.sidebar_btn_box
    width: 200px
    height: 48px
    padding: 8px
    border-radius: 8px
    gap: 8px
    display: flex
    justify-content: center
    align-items: center
    background: #00000008
    // border: 1px solid var(--color-input-border-light)
    border-radius: calc(var(--base-border-radius) * 0.5)
    +dark
        background: #FFFFFF08
        // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08)

.sidebarbottonbtn
    width: 81px
    height: 32px
    padding: 8px 16px 8px 16px
    border-radius: 8px
    display: flex
    justify-content: center
    align-items: center
    gap: 4px
    +dark

.sidebar_btn_light
    width: 81px
    height: 32px
    padding: 8px 16px 8px 16px
    border-radius: 8px
    display: flex
    justify-content: center
    align-items: center
    gap: 4px
    background: var(--color-bg-90-light)
    +dark
        color: var(--color-lavender-gray)
        background: #FFFFFF08

.sidebar_btn_dark
    width: 81px
    height: 32px
    padding: 8px 16px 8px 16px
    border-radius: 8px
    display: flex
    justify-content: center
    align-items: center
    gap: 4px
    +dark
        color: #BCC3D6
        background: var( --color-black)

.sidebarBtnIconLight
    color: #747E99
    height: 16px
    width: 16px
    display: flex
    align-items: center

    +dark
        color: #878C99

.sidebarBtnIcon
    display: flex
    align-items: center
    color: #747E99
    height: 16px
    width: 16px
    +dark
        color: #BCC3D6

.btn_textlight
    color: #464C5C
    +dark
        color: #878C99
.btn_text
    color: #464C5C
    +dark
        color: #BCC3D6

.sidebar__collapse_button
    position: absolute
    bottom: 24px
    right: -15px
    top: 70px
    width: 30px
    height: 30px
    background: var(--bg-primary)
    color: var(--adf-color-text-80-light)
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    z-index: 99
    border-radius: 50%
    border: 1px solid #d7dff5
    transition: bottom 0.3s ease-in
    +dark
        background: $n17
        border: 1px solid #43464d

.collapsedTheme
    padding-left: 16px

.subLine 
    background-color: var(--color-primary-medium)
    height: 24px
    width: 2px
    margin-left: 28px


.MlcLogo
    height: 36px !important
    margin-top: 15px
    margin-left: 8px    