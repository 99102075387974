@import "../../../../styles/helper";

.signin-input-wrapper{
  .country-code-container{
    background: #FFFFFF10;

    .arrow{
      color: $white;
    }
  }

  .country-code-container > .option-container{
      //  background: $n19 !important;
       color: white;
    }

  .option-selected {
    border-right: 0.5px solid $n3;
  }

  .option-content > span{
    color: white !important;
  }

  .input__error {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: $n5;
  }
}
.login-divider {
  text-align: center;
  border-bottom: 1px solid #d7dff5;
  width: 100%;
  line-height: 0.1rem;
  span {
    padding: 0px 20px;
    background: black;
    font-size: 16px;
    color: #a2a7b8;
    font-weight: 500;
  }
}
.header_nav {
  ul{
    display: flex;
    list-style: none;
    gap: 15px;
    li > span {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-transform: capitalize;
        color: #1472FF;
      cursor: pointer;
        &:hover{
        color: #7398fc;
        transition: 0.4s all ease-in-out;
      }
    }
  }
 
}
.bannerImage {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
  padding: 0;
}
