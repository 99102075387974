@import ../../../../styles/helpers
.mainContainer
    margin: auto
    margin-top: 16px
    max-width: 768px
    width: 100%
    +s
        margin-bottom: 100px

.firstContainer
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    max-width: 768px
    width: 100%

    text-align: center

.profilePic
    cursor: pointer
    position: relative
    height: 96px
    width: 96px
    
.brand_logo
    height: 96px
    width: 96px
    border-radius: 100%
    object-fit: cover

.imageName
    align-items: center
    border-radius: 50px
    background: #F4F5F6
    display: flex
    justify-content: center
    height: 96px
    width: 96px
    font-size: 24px
    font-weight: 600
    line-height: 36px
    color: #1D1E21
    border: 1px solid #878C99
    +dark
        background: #282A2E
        border: 1px solid #43464D
        color: #FFFFFF




.profileImage
    height: 96px
    width: 96px
    border-radius: 50%
    object-fit: cover
    border: 1px solid #43464D
    +dark
        background: #282A2E
        border: 1px solid #43464D

.gallary
    position: absolute
    top: 62px
    right: 3px
    background: #4574F5
    border-radius: 50%
    height: 32px
    width: 32px
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

.buttonsContainer
    position: absolute
    top: 100px
    left: 70px
    display: grid
    gap: 5px
    padding: 10px 10px
    background-color: $n7
    min-width: 160px
    white-space: nowrap
    +dark
        background-color: $n2
    
    .picButtons
        font-weight: 500
        display: flex
        gap: 5px
        +dark
            color: $white
        .picIcons
            font-size: 18px

.gallaryImage
    height: 12px
    width: 13.461333274841309px


.name
    font-size: 16px
    line-height: 24px
    font-weight: 700
    padding-top: 8px
    color: #1D1E21
    +dark
        color: #FFFFFF

.accid
    font-size: 12px
    font-weight: 500
    color: $n11
    
.id
    font-size: 12px
    line-height: 16px
    font-weight: 500
    color: #878C99

.secondaryContainer
    display: flex
    flex-direction: column
    +t
        padding: 0 10px


.accountVerified, .accountPending
    color: $dark
    font-weight: 500
    font-size: 14px
    margin-right: 10px
    +dark
        color: $white
    &:before
        content: ""
        display: inline-block
        padding: 4px
        border-radius: 50%
        background-color: $n13
        margin-inline: 5px

.accountPending
    &:before
        background-color: $n10

.emailResend, .accountInitiate
    color: $n10
    font-weight: 500
    cursor: pointer
    display: inline-flex
    white-space: nowrap

    .loaderBlueIcon
        border: 2px solid transparent
        border-top-color: white

.accountInitiate
    color: $p1

.emailVerified
    color: $n13




.details
    display: flex
    max-width: 768px
    width: 100%
    justify-content: space-between
    border-bottom: 0.5px solid #CCD2E0
    padding: 16px
    +dark
        border-bottom: 0.5px solid #36383D
    .editIcon
        cursor: pointer
        padding-inline: 5px
        font-size: 16px
        color: $p1

.detailTitle
    font-size: 14px
    font-weight: 500
    line-height: 20px
    color: #6C7489
    &_dvpRejected
        padding-top: 10px
        font-weight: 400
        font-size: 14px
        display: flex
    +s
        font-size: 12px
        margin-right: 10px


.detail
    font-size: 14px
    font-weight: 500
    line-height: 20px
    color: #363A45
    display: flex
    align-items: center
    justify-content: right
    gap: 8px
    overflow: hidden
    +s
        font-size: 12px
    +dark
        color: #FFFFFF
    .custom
        border-radius: 4px
        height: 40px
        min-height: 40px
        font-weight: 500
        font-size: 14px
    .dvpIcon
        color: $s5
    .dvpCancel
        color: $n77
        cursor: pointer

.dvpOuterdetails
    max-width: 768px
    width: 100%
    align-items: center
    border-bottom: 0.5px solid #CCD2E0
    padding: 16px
    +dark
        border-bottom: 0.5px solid #36383D

.dvpdetails
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 16px

.dvpBar_wrapper
    background-color: $n7
    display: flex
    flex-wrap: nowrap
    width: 100%
    justify-content: flex-end
    padding: 8px 16px
    +dark
        background-color: #FFFFFF08
    .dvpBar_container
        padding: 8px 16px
    .dvpBar_Price
        padding-left: 20px
        border-left: 1px solid $n4
    .priceheading
        font-size: 14px
        font-weight: 400
        line-height: 20px
        text-align: right
        color: $n9
    .priceValue
        font-size: 20px
        font-weight: 600
        line-height: 32px
        text-align: right


.vectorImg
    padding-left: 6px

.space
    display: flex
    justify-content: center
    align-items: center
    gap: 2px

.maskDots
    height: 4px
    width: 4px
    border-radius: 32px
    background: #363A45
    margin-top: 8px
    +dark
        background: #878C99

.dotColor
    height: 8px
    width: 8px
    border-radius: 50%

.approvIcon
    color: green
    font-size: 15px
    margin-right: 5px

.rejectedIcon
    color: rgba(235, 71, 71, 1)
    font-size: 20px
    margin-right: 5px
    

.approved
    background: #33b87a

.rejected
    background: red
.pending
    background: #e5ae40
.processing
    background: #e5ae40


.kyc
    text-transform: capitalize

.accreditationUpgrade
    margin-top: 5px
    text-align: right
    color: $n74
    cursor: pointer
    +dark
        border-left-color: $n4

.accreditationUpgradeModal
    max-width: 576px !important
    padding: 24px
    border-radius: 8px
    +dark
        background: $n21 !important

.DVPRequest
    padding: 4px 16px
    border-radius: 4px
    height: 32px
    width: 88px
    font-size: 14px
    font-weight: 600
    line-height: 24px
    background: $n28
    cursor: pointer
    color: $white

.Esign
    height: 95vh
.requestedIcon
    color: $n53 !important
.CreateIssuerName
    display: flex
    border-left: 1px solid $n3
    padding-left: 4px
    font-size: 14px
    font-weight: 600
    line-height: 20px
    color: $n74
    cursor: pointer

.accditationLeft
    border-right:  1px solid $n5
    padding-right: 10px
    margin-right: 2px
    cursor: pointer

    

.IssuerName
    display: flex
    gap: 8px
    &__IssuerName
        font-size: 14px
        font-weight: 500
        line-height: 20px
    &__editIcon 
        font-size: 20px   
.KYCReviewModal
  max-width: 768px !important
  padding: 24px
  border-radius: 8px
  +dark
    background: $n21 !important   

.emailValue
    max-width: 400px
    overflow: hidden
    white-space: nowrap
    cursor:pointer
    text-overflow: ellipsis
    