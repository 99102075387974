@import "../../../../styles/helper.scss";

.notificationSection{
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 12px 16px;
    border-bottom: 1px solid $n54;
    cursor: pointer;
    @include dark{
        border-bottom: 1px solid $n12;
    }
    @media (max-width: 425px) {
        max-width: 425px;
    }
}
.walletNotification{
    display: flex;
    justify-content: space-between;
    &__left{
        display: flex;
        position: relative;
        gap: 12px;
        &__icon{
            width: 40px;
            height:40px;
            gap: 8px;
            border-radius: 4px;
            background: $n57;
            @include dark{
                background: $n12;
            }
            i{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
            }
            .deposit{
                color: $n13;
            }
            .withdraw{
                color: $n24;
            }
        }
        &__readMark{
            height: 8px;
            width: 8px;
            border-radius: 24px;
            background: $n62;
            position: absolute;
            left: 38px;
            top: -2px;
        }
        &__title{
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: $n58;
            @include dark {
                color: $white;
            }
            
            
        }
        &__Subtitle{
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: $n59;
            margin-top: 4px;
            @include dark{
                color:$n11;
            }
        }
        &__userDetail{
            display: flex;
            gap: 4px;
            margin-top: 12px;
            align-items: center;
            &__userImg{
                height: 24px;
                width: 24px;
                object-fit:cover;
                border-radius: 40px;

            }
            &__userName{
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: $n45;
                @include dark{
                    color: $n15;
                }
            }

        }
        &__bankDetails{
            color: $n11;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            margin-top: 12px;
        }
    }
    &__right{
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        gap: 4px;
        &__amount{
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color:$n58;
            @include dark {
                color:$n14;
            }
        }
        &__dot{
            height: 8px;
            width: 8px;
            border-radius: 24px;
        }
        &__status{
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 4px;
        line-height: 16px;
        color: $n15;
    }
        &__orderDetails{
            display: flex;
            align-items: center;
            gap: 8px;
            &__qty{
                display: flex;
                gap: 8px;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
               div{
                color: $n15;
                @include dark{
                    color:$n46;
                }
               } 
                span{
                    color: $n45;
                @include dark{
                    color: $n15;
               }
            }
        }
            &__bullet{
                width: 4px;
                height: 4px;
                border-radius: 4px;
                background: $n46;
            }
            &__price{
                display: flex;
                gap: 8px;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                div{
                    color: $n15;
                @include dark{
                    color:$n46;
                }
                } 
                span{
                    color: $n45;
                @include dark{
                    color: $n15;
                }
                }
            }
            &__orderType{
                margin-top: 12px;
                margin-bottom: 8px;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                display: flex;
                gap: 8px;
                div{
                padding: 4px 12px;
                background-color: $n57;
                color: n60;
                border-radius: 4px;
                @include dark{
                        background: $n61;
                        color: $n11;
                    }
                    } 
                    span{
                        padding: 4px 12px;
                        border-radius: 4px;
                        background-color: $n57;
                        color: $n1;
                        @include dark{
                        background: $n61;
                        color: $n15;
                    }
                    }
            }
        }
        &__time{
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: $n11;
            text-align: right;
            margin-top: 41px;
        }
    }
    
}
.processing {
    background-color: $s5;
  }

  .cancelled {
    background-color: $n23;
  }

  .completed {
    background-color: $n13;
  }

  .failure {
    background-color: $n24;
  }