@import "../../styles/helper.scss";


.resend-verify-container {
  padding: 10px 20px;
  background-color: $blue;
  color: $white;
  // position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  font-size: 14px;
  cursor: pointer;
  .resend-verify-hove{
    &:hover{
      opacity: .8;
    }
  }

  span {
    font-weight: 600;
  }

  .resent-btn {
    font-size: 14px;
    border: 1px solid $white;
    font-weight: normal;
    background-color: transparent !important;
    border-radius: 4px;
    padding: 2px 5px;
    margin-left: 5px;
    height: auto;
    min-height: auto;

    .loader-blue {
      border-top-color: white;
    }
  }

  .close-icon {
    float: right;
    cursor: pointer;
    font-size: 20px;
    line-height: normal;
  }
}
