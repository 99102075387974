@import styles/helpers

.wrapper
    width: 100dvw
    height: 100vh
    place-items: center
    display: grid
    position: relative
    background: black

.inner_wrapper
    max-width: 514px
    padding: 0 9px
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
.brand_logo
    height: 32px
    position: absolute
    top: 30px
    left: 30px
.inner
    border-radius: 8px
    padding: 48px 32px
    width: 100%
    display: flex
    flex-direction: column
    gap: 24px
.heading
    display: flex
    flex-direction: column
    gap: 4px
.title
    font-weight: 700
    font-size: 30px
    line-height: 40px
    color: $white

.sub_title
    font-weight: 500
    font-size: 15px
    line-height: 24px
    color: #AFB6C0

.lists
    display: flex
    flex-direction: column
    gap: 8px

.list_item
    display: flex
    gap: 8px
    align-items: center
    border-radius: 8px
    padding: 8px 12px
    border: 1px solid transparent
    cursor: pointer
    color: $n13
    background: #1C1D1F
    transition: border-color 0.3s ease
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
    transition: all 0.3s ease
    &:hover
        border: 1px solid #478FFB
        transform: translateY(-2px)
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3)

.list_item_disabled
    cursor: not-allowed
    pointer-events: none
    opacity: 0.7
    .left_icon
        background: rgba(51, 184, 122, 0.12)

.left_icon
    background: rgba(51, 184, 122, 0.12)
    border-radius: 24px
    width: 40px
    height: 40px
    display: grid
    place-items: center
    font-size: 24px
.editChange
    color: #478FFB
    cursor: pointer
    &:hover
        transition: color 0.3s ease

.right_icon
    font-size: 24px
    height: 40px
.method
    width: 380px
    display: flex
    flex-direction: column
    gap: 4px
    flex: 1 1

.method_title
    font-weight: 600
    font-size: 16px
    line-height: 24px
    color: $n14
    flex: 1

.method_subTitle
    color: #AFB6C0
    font-size: 14px
    font-weight: 400
    line-height: 20px
.arrowRight
    color: #AFB6C0

.back_btn
    display: flex
    gap: 8px
    width: 40%
    align-items: center
    background: gray
    border-radius: 8px
    padding: 16px
    text-align: center
    height: 40px
    align-self: center
    cursor: pointer
    color: $n13
    &:hover
        background: rgba(245, 175, 69, 0.06)
        color: $s5

.edit_Btn   //Todo: unable to find the use of this class
    cursor: pointer
    text-decoration: underline
    text-align: center
    width: 36px
    &:hover
        color: $p1
        font-weight: 700

.edit_text
    display: flex
    color: #D1D5DB
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 8px
    font-size: 16px
    font-weight: 500
    line-height: 24px

.fingerprint_icon
    background: #50C17D
    color: white
.smartphone_icon
    background: #FB478F
    color: white
.message_icon
    background: #9537F3
    color: white
