@import "../../../../styles/helper";
.screen-container {
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px;
  justify-content: center;
  align-items: center;

  span {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    border-radius: 136px;
    padding: 16px;
    background: rgba(0, 0, 0, 0.158824);
    margin-top: 18px;
   

    i {
      font-size: 48px;
    }
  }
  .account-added{
    font-size: 20px;
    font-weight: 600;
    font-family: Poppins;
    color: (245, 245, 245, 1);
  }
  .deposit-amount{
    font-size: 14px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    font-family: Poppins;
  }
}
.deposit-amount{
  font-size: 14px;
  font-weight: 400px;
}
.add-new-button{
  background-color: $p1;
  padding: 0 24px;
  height: 48px;
  color: $n8;
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0px 20px 0px;
  border-radius: 4px;
}