@import styles/helpers

.currency
  border-right: 1px solid $n6
  background: $n8
  position: relative
  display: flex
  flex-direction: column
  flex: 1 1
  overflow-y: auto

  +poppins
  +dark
    background: $dark
    border-color: $n2

.loader
  width: 100%
  display: flex
  justify-content: center
  padding-top: 150px

.nav
  display: flex
  overflow: scroll
  border-bottom: 1px solid $n6
  padding: 0px 20px
  width: 100%

  &::-webkit-scrollbar
    display: none

  +dark
    border-bottom: 1px solid $n2

.link
  display: flex
  padding: 16px
  background: none
  text-transform: capitalize
  min-width: max-content
  +poppins
  font-weight: 500
  font-size: 12px
  color: #777E91
  transition: all .2s
  border-bottom: 1px solid transparent
  @media ((min-device-width: 481px))
    &:hover
      color: $blue
      border-bottom-color: $blue
      +dark
        color: $n10
        border-bottom-color: $n10

  &.active
    color: $blue
    border-bottom-color: $blue
    +dark
      color: $n10
      border-bottom: 1px solid $n10
 

.activeSbaOrderBook
  border: 1px solid $n74 !important
  background: $n75 !important
 
.TypeOrderBookSeries
  font-size: 12px
  font-weight: 500
  line-height: 16px
  position: absolute
  bottom: 10px
  right: 10px
  padding: 4px 8px
  border-radius: 32px
  background: $n33

  +dark
    color: $n64
    background: $n36
  

.form
  position: relative
  padding: 16px
  border-bottom: 1px solid $n6
  +dark
    border-bottom: 1px solid $n2


.search
  position: absolute
  background: $white
  width: 100%
  height: calc(100vh - 64px)
  +dark
    background: $dark

.input
  width: 100%
  padding: 14px 40px 14px 14px
  border-radius: 8px
  background: none
  border: 2px solid $n6
  +poppins
  +caption-2
  color: $n2
  transition: border-color .2s
  +dark
    border-color: $n3
    color: $n8
  +placeholder
    color: $n4

  &:focus
    border-color: $n4

.result
  position: absolute
  top: 0
  right: 16px
  bottom: 0
  width: 40px
  cursor: auto

  svg
    fill: $n4
    transition: fill .2s


.table
  display: flex
  flex-direction: column
  width: 100%
  overflow-y: auto
  flex: 1 1
  +scroll

.row
  border-bottom: 1px solid $n6
  padding: 8px 16px 12px
  display: flex
  justify-content: space-between
  align-items: flex-start
  gap: 2px
  cursor: pointer
  position: relative
  +poppins
  +dark
    border-color: $n2

  .left
    width: 100%
    display: grid
    grid-template-columns: 32px auto
    column-gap: 12px
    align-items: center
    row-gap: 4px
    text-align: left
    .currentYield_tooltip
      left: 27px !important
  .activeSbaOrderBookLeft
    width: 240px    

  .symbol
    font-weight: 600
    font-size: 14px
    line-height: 20px
    max-height: 135px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

    +dark
      color: $white

  .compony_name
    width: 100%
    font-weight: 500
    font-size: 12px
    line-height: 16px
    color: $n11
    span
     +dark
        color: $white
  .draftAsset 
    span 
      padding: 3px 8px
      font-size: 12px
      margin: 8px 4px 0 0
      display: inline-block
      border-radius: 4px
      border: 1px solid black
      color: black
      +dark
        border: 1px solid #FFFFFF1F
        color: #B8B8B8
        
     



  .right
    width: 50%
    display: flex
    flex-direction: column
    align-items: flex-end
    gap: 4px

  .current_price
    font-weight: 600
    font-size: 14px
    line-height: 20px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    +dark
      color: $white

  .price_change
    font-weight: 500
    font-size: 12px
    line-height: 16px
    color: $p3
    display: flex
    gap: 4px
    align-items: center

  &:hover
    background: $n6
    +dark
      background: rgba(255, 255, 255, 0.03)

  &.active
    background: $n6
    +dark
      background: rgba(255, 255, 255, 0.03)

.informationTooltip
  cursor: pointer
  margin-left: 2px

.noData
  width: 100%
  height: 100%
  position: relative
  background: $white
  z-index: 1
  flex: 1 1 

  +dark
    background: $dark

.noDataContent
  width: 100%
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.noDataHeading
  margin-top: 20px
  font-weight: 600
  font-size: 20px
  line-height: 32px

.noDataPara
  font-weight: 400
  font-size: 14px
  line-height: 20px
  text-align: center
  color: $n11
  +dark
    color: $n16

.ISLoader
  padding-top: 16px
  padding-bottom: 16px

.pre_button
  position: absolute
  height: 52px
  background: $white
  font-size: 20px
  border-top-left-radius: 8px
  border-bottom: 1px solid $n6
  left: 0px
  display: flex
  justify-content: center
  align-items: center
  color: #777E91

  &:hover
    color: $n10

  +dark
    background: $dark
    border-bottom: 1px solid $n2

.next_button
  position: absolute
  height: 52px
  background: $white
  font-size: 20px
  border-top-left-radius: 8px
  border-bottom: 1px solid $n6
  right: 0px
  display: flex
  justify-content: center
  align-items: center
  color: #777E91

  &:hover
    color: $n10

  +dark
    background: $dark
    border-bottom: 1px solid $n2

.assetImageContain
  grid-row: 1 / span 2
  width: 32px
  height: 32px
  border-radius: 50%
  object-fit: contain
  font-size: 16px
  background: #f7f7f7
  position: relative
  +dark
    background: $n2
  display: flex
  border-radius: 50%
  justify-content: center
  align-items: center
.middlePart
  width:170px
.accreditationLabel
  position: absolute
  top: calc(100% - 10px)
  width: 46px


.sba  
  .assetImageContain
    grid-row: 1 / span 3
     margin-top: 8px
  .left
    align-items: unset
  .company_name
    span
      color: $white  
   

    
.assetImage
  width: 32px
  height: 32px
  border-radius: 50%
  font-size: 20px
  +dark
    background: $n2
  display: flex
  border-radius: 50%
  justify-content: center
  align-items: center

  img
    height: 46px
    width: 46px
    border-radius: 50%
    object-fit: contain

.forexImageContain
  padding-top: 10px
  padding-right: 10px

.imagewrapper
  width: 32px
  height: 32px
  border-radius: 50%
  position: relative

.imagefromcontiner
  width: 20px
  overflow: hidden
  border-radius: 8px
  z-index: 10
  margin-left: 7px
  position: relative
  top: -4px
  left: -4px

  .imagefrom
    border-radius: 8px
    max-width: 20px
    max-height: 20px

.imagetocontiner
  width: 24px
  height: 20px
  position: relative
  border-radius: 8px
  bottom: 8px
  right: 8px

  .imageto
    border-radius: 8px
    max-width: 20px
    max-height: 20px
    position: absolute
    left: 16px
    top: -8px
    width: 100%

  .skeleton_asset
    min-width: 180px
    padding-left: 12px

.hide
  visibility: hidden
  pointer-events: none

.positive
  color: $n13

.negative
  color: $p3

.neutral
  color: $n11
.realEstateAddress
  font-size: 12px
  line-height: 16px
  font-weight: 500
  color: #999999
  width: 180px
.activeRealEstate
  height: 68px

.cryptoRow
  padding: 6px 16px
  .assetImageContain
    width: 40px
    height: 40px
    .assetImage
      width: 40px
      height: 40px


.brand_logo
  width: auto
  height: 64px
  cursor: pointer
  max-width: 258px

.logo1
  display: none
  object-fit: contain
  font-size: 24px
  font-weight: 600
  color: #000
  padding: 0px 10px
  justify-content: space-between
  // text-align: center
  img
    border-radius: unset
  +dark
    color: $s3
  +t
    display: flex
  +m
    position: relative
    z-index: 15
    display: flex
    align-items: center

