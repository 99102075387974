@import "../../../../../../styles/helper.scss";
.wire-transfer-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include dark {
    color: $n64;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .modal-conten-hide{
    visibility: hidden;
  }
  .wire-transfer-container {
    padding: 24px 24px 16px;
    border-bottom: 1px solid #0000001f;
    @include dark {
      border-bottom: 1px solid $n35;
    }
  }

  .modal-content {
    background-color: $white;
    border-radius: 8px;
    width: 100%;
    max-width: 768px;
    position: relative;
    color: #1e1e1e;
    @include dark {
      background: #1D1E20;
      color: $n64;
    }
  }

  .close-button {
    position: absolute;
    top: 4px;
    right: 8px;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 4px;
    line-height: 1;
    @include dark {
      color: #999999;
    }
  }

  &__wire-title{
    font-size: 20px;
    font-weight: 600;
    color: $n21;
    @include dark {
      color: $n64;
    }
  }

  &__wire-subtitle {
    color: $n30;
    font-weight: 400;
    font-size: 14px;
    @include dark {
      color: #B8B8B8;
    }
  }

  .amount-field {
    background-color: #2a2a2a;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;

    .amount-input {
      display: flex;
      align-items: center;
      font-size: 32px;
      margin-bottom: 8px;

      .currency {
        margin-right: 4px;
      }

      input {
        background: none;
        border: none;
        font-size: 32px;
        width: 100%;
        outline: none;

        &.error {
          color: #ff4444;
        }
      }
    }

    label {
      color: #a0a0a0;
      font-size: 14px;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    align-items: baseline;
    gap: 4px;

    label {
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 16px;
      color: #5C5C5C;
      @include dark {
        color: #B8B8B8;
      }
    }

    input {
      width: 100%;
      background-color: #00000008;
      border: 1px solid $n49;
      border-radius: 4px;
      padding: 11px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      @include dark {
        background-color: #ffffff08;
        border: 1px solid $n36;
        color: $white;
      }

      &::placeholder {
        color: #7A7A7A;
      }

      &:focus {
        border-color: #4a90e2;
        outline: none;
      }

      &.error {
        border-color: #ff4444;
        background-color: #EF43430F;
      }
    }
  }

  .form-row {
    display: flex;
    gap: 20px;

    .form-group {
      flex: 1;
    }
  }

  .error-message {
    color: #ff4444;
    font-size: 14px;
    margin-top: 4px;
    display: block;
    font-weight: 400;
    line-height: 20px;
  }

  .progress-bar {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 308px;
    gap: 16px;
  }

  .progress-line {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 0px;
    height: 2px;
    background-color: $n68;
    z-index: 0;
  }

  .progress-fill {
    position: absolute;
    top: 12px;
    left: 14px;
    height: 2px;
    width: 0%;
    background-color: $n74;
    color: $n74;
    z-index: 1;
    transition: width 0.3s ease-in-out;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    min-width: 86px;
    &:not(:nth-child(3)){
      align-items: center;
    }
    &:last-child {
      align-items: end;
    }
  }

  .step-number {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d3d3d3;
    color: $n68;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    border: 1px solid $n68;
    background-color: $white;
    @include dark {
      background: #1e1e1e;
    }
  }

  .step.active .step-number {
    color: $n74;
    border: 1px solid $n74;
    z-index: 1;
    @include dark {
      color: $n74;
      border: 1px solid $n74;
    }
  }

  .step.completedStep .step-number {
    color: $white;
    border: 1px solid $n74;
    background-color: $n74;
  }

  .step span {
    white-space: nowrap;
    margin-top: 5px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #7A7A7A;
    @include dark {
      color: #999999;
    }
  }

  .btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    &__cancel-button {
      color: rgb(23, 24, 27);
      background: rgba(0, 0, 0, 0.12);
      padding: 12px 24px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;
      @include dark {
        color: #d6d6d6;
        background: #3a3b41;
      }

      &:hover {
        background-color: #d6d6d6;
        @include dark {
          background-color: #4b4c52;
        }
      }
    }
        &__submit-button {
          background-color: #0061f5;
          min-width: 194px;
          border: none;
          color: $white;
          padding: 12px 24px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          transition: background-color 0.2s;
    
          &.btn-min-width {
            min-width: 100px;
          }
    
          &:hover {
            background-color: #0559d7;
          }
        }
  }
}

.withdrawAmount {
  height: 104px;
  padding: 12px;
  gap: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #0000001f;
  background-color: #00000008;
  @include dark {
    background-color: #ffffff08;
    border: 1px solid $n36;
  }

  &__amountContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__dollarSign {
    font-size: 40px;
    font-weight: 500;
    line-height: 56px;
  }

  &__amounttodeposit {
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    @include dark {
      color: #999999;
    }
  }

  &__amountInput {
    height: 56px;
    background: transparent;
    font-size: 40px;
    font-weight: 500;
    line-height: 56px;
    color: $n21;
    @include dark {
      color: #f5f5f5;
    }
  }
  &--error {
    border-color: #ff4444 !important;
    background-color: #EF43430F !important;
  }
}

.footer-wired {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #0000001f;
  gap: 16px;
  padding: 16px 24px;
  @include dark {
    border-top: 1px solid $n35;
  }
}

.bank-selection {
  position: relative;
  margin-top: 16px;
  display: grid;
  gap: 4px;
  @include dark {
    background-color: #1e1e1e;
    color: $white;
  }
}

.dropdown {
  position: relative;
  padding: 10px;
  border: 1px solid #0000000f;
  border-radius: 5px;
  background: #00000008;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include dark {
    background-color: #ffffff08;
    color: $white;
    border: 1px solid $n36;
  }

  .arrow {
    font-size: 12px;
    margin-left: 8px;
  }

  &:hover {
    background-color: #f0f0f0;
    @include dark {
      background-color: #302f2f;
      color: $white;
    }
  }
}

.dropdown-menu {
  max-height: 256px;
  position: absolute;
  overflow: auto;
  top: 100%;
  left: 0;
  width: 100%;
  background: $white;
  border: 1px solid $n35;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 5px;
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  @include dark {
    background-color: #1e1e1e;
    color: white;
  }

  .dropdown-item {
    display: flex;
    gap: 16px;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #0000001f;
    transition: background-color 0.2s ease-in-out;
    .dropdown-label {
      width: 100%;
    }
    @include dark {
      border-bottom: 1px solid $n35;
    }

    strong {
      display: block;
      font-weight: 600;
    }

    p {
      margin: 2px 0;
      font-size: 14px;
      color: #666;
    }

    span {
      font-size: 12px;
      color: #888;
    }

    &:hover {
      background-color: #f0f0f0;
      @include dark {
        background-color: #302f2f;
        color: $white;
      }
    }
  }
  .dropdown-radio {
    margin-bottom: 46px;
    &__newBank {
      margin-bottom: 24px;
    }
  }
}
