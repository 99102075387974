@import "../../../../styles/helper";
.comingsoonContainer{

}
.main {
    display: block;
    position: relative;
    padding-left: 24px;
    // margin-bottom: 15px;
    cursor: pointer;
    font-size: 20px;
    height: 18px;
    width: 18px;
}

.main input[type=checkbox] {
    visibility: hidden;
}

.checkbox-container {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    background-color: $n19;
    border: 1px solid $n43;
    &.checkbox-error{
        border-color: var(--color-error);
    }
}


/* Checkmark */
.checkbox-container::after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid $n28;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Display checkmark when checked */
.main input:checked~.checkbox-container::after {
    display: block;
}

.login-checkbox-label {
    cursor: pointer;
    .checkbox-container::after {
        border-color: white;
    }

    input:checked ~ .checkbox-container {
        background-color: $n74;
    }
}
