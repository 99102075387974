@import "../../../../styles/helper.scss";

.bid-card-container {
  padding: 10px;
  display: flex;
  background-color: $white;
  border-radius: 4px;
  @include dark {
    background-color: $dark;
  }

  .bid-card-image {
    border-radius: 4px;
    overflow: hidden;
    width: 330px;
    height: 170px;
    position: relative;
    border: 1px solid $n6;
    background-color: $n6;
    display: flex;
    justify-content: center;
    align-items: center;

    &.sba-image {
      height: 229px;
    }

    .ri-image-add-line {
      font-size: 50px;
      color: $n4;
    }

    .label,
    .h-icon {
      position: absolute;
      right: 10px;
      background-color: rgba(93, 92, 92, 0.64);
    }

    .label {
      font-size: 12px;
      font-weight: 600;
      top: 10px;
      color: $white;
      padding-inline: 8px;
      border-radius: 50px;
      text-transform: capitalize;
    }

    .h-icon {
      border-radius: 50%;
      bottom: 10px;
      color: $white;
      font-size: 20px;
      padding-inline: 8px;
      cursor: pointer;

      &.ri-heart-3-fill {
        color: $p3;
      }

      &.h-icon-reload {
        width: 33px;
        height: 33px;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    .arrow-label {
      position: absolute;
      left: 0;
      top: 10px;
      background-color: $s6;
      padding: 0 6px;
      font-size: 12px;
      color: $white;
      text-transform: capitalize;

      .arrow-label-img {
        position: absolute;
        left: 100%;
        height: 100%;
      }
    }

    @include dark {
      border-color: $n19;
    }
  }

  .bid-card-details {
    padding-left: 15px;
    display: grid;
    grid-template-columns: 10fr 2fr;
    flex: 1 1;

    .logo-container {
      display: flex;
      align-items: start;
      margin-bottom: 15px;

      .logo-img {
        width: 40px;
        height: 40px;
        border: 1px solid $n6;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .ri-file-upload-line {
          font-size: 20px;
          color: $n4;
        }

        &.with-bg {
          background-color: $n6;
        }

        img {
          width: 100%;
          height: 100%;
        }

        @include dark {
          border-color: $n4;
        }
      }

      .title-heading-symbol {
        padding-left: 10px;
        line-height: 1.3;
        flex: 1 1;
      }

      .title-heading {
        font-size: 16px;
        width: 23ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title-symbol {
        font-size: 14px;
        color: $n4;

        span {
          color: $n2;
        }
      }

      @include dark {
        .title-heading {
          color: $white;
        }

        .title-symbol {
          span {
            color: $n5;
          }
        }
      }
    }

    .time-container {
      display: flex;
      justify-content: end;

      .time-count {
        font-size: 14px;
        font-weight: 500;
        color: $n4;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: normal;
        min-width: 140px;

        & > span {
          font-weight: 600;
          font-size: 16px;
          display: inline-flex;
        }

        .time-red {
          color: $p3;
        }

        .time-color {
          color: black;
        }

        @include dark {
          color: $n5;

          .time-color {
            color: $white;
          }
        }
      }

      .count-circle {
        width: 96px;

        .CircularProgressbar-path {
          stroke: $p4;
          stroke-linecap: butt;
        }

        &.time-red {
          .CircularProgressbar-path {
            stroke: $p3;
          }
        }

        &.time-over {
          .CircularProgressbar-trail {
            stroke: $p3;
          }
        }
      }

      .inner-time-circle {
        width: 65px;

        .CircularProgressbar-text {
          font-size: 18px;
          stroke: $p1;
        }

        .CircularProgressbar-path {
          stroke: $p1;
        }

        .CircularProgressbar-trail {
          stroke: $p1_12;
        }
      }
    }

    .price-bids-box {
      padding-top: 10px;
      display: grid;
      grid-template-columns: auto auto auto auto;
      align-items: flex-start;
      gap: 6px;
      justify-content: space-between;
    }

    .price-bits {
      .price {
        font-size: 16px;
        font-weight: 600;
        line-height: 15px;
      }

      .price-label {
        font-size: 12px;
        font-weight: 500;
        color: $n4;
      }

      @include dark {
        .price {
          color: $white;
        }
        .price-label {
          color: $n5;
        }
      }
    }

    .date-and-buttons {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .bid-buttons {
        display: flex;
        justify-content: space-between;
        gap: 4px;

        .bid-buttons-grp {
          display: flex;
          gap: 4px;
        }
      }

      .bid-btn {
        border-radius: 90px;
        padding: 10px;
        min-height: auto;
        height: auto;
        font-size: 14px;
        font-weight: 500;
        width: 130px;
      }

      @include dark {
        .btn-grey-1 {
          background-color: $n12 !important;
          color: $white !important;
        }
      }
    }
  }
}
